import {
  GetCheckoutCartRequest,
  GetPurchaseInfoRequest,
  GetShopRequest,
  InitializePurchaseRequest,
  MockWebhookCallRequest,
} from 'api/dtos/payment/shop/dto/request';
import {
  GetCheckoutCartResponse,
  GetPurchaseInfoResponse,
  GetShopResponse,
  InitializePurchaseResponse,
} from 'api/dtos/payment/shop/dto/response';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { RequestMethod } from 'types/request';

export const shopApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getShop: builder.query<GetShopResponse, GetShopRequest>({
      query: (body: GetShopRequest) => ({
        url: `/api/purchase/shop`,
        method: RequestMethod.POST,
        body,
      }),
    }),
    getCheckoutCart: builder.query<GetCheckoutCartResponse, GetCheckoutCartRequest>({
      query: (body: GetCheckoutCartRequest) => ({
        url: `/api/purchase/checkout-cart`,
        method: RequestMethod.POST,
        body,
      }),
    }),
    initializePurchase: builder.mutation<InitializePurchaseResponse, InitializePurchaseRequest>({
      query: (request) => ({
        url: '/api/purchase/initialize',
        method: RequestMethod.POST,
        body: request,
      }),
    }),
    getFeasibilityStudyPriceByArea: builder.query<number, number>({
      query: (area: number) => ({
        url: `/api/open-request/feasibility-study-price/${area}`,
        method: RequestMethod.GET,
      }),
    }),
    getPurchaseInfo: builder.query<GetPurchaseInfoResponse, GetPurchaseInfoRequest>({
      query: ({ transactionId }) => ({
        url: `/api/purchase/transaction/${transactionId}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PURCHASE_INFO],
    }),
    mockWebhookCall: builder.mutation<void, MockWebhookCallRequest>({
      query: (body) => ({
        url: '/api/webhooks/datatrans',
        method: RequestMethod.POST,
        body,
      }),
      invalidatesTags: [EBackendTag.CREDITS, EBackendTag.PURCHASE_INFO],
    }),
  }),
});

export const {
  useGetCheckoutCartQuery,
  useGetFeasibilityStudyPriceByAreaQuery,
  useGetPurchaseInfoQuery,
  useGetShopQuery,
  useInitializePurchaseMutation,
  useLazyGetCheckoutCartQuery,
  useMockWebhookCallMutation,
} = shopApiSlice;

export const useGetShopQueryState = shopApiSlice.endpoints.getShop.useQueryState;
