import { TBuildings } from 'types/building/Building';
import { TExistingEditableBuildings } from 'types/building/ExistingEditableBuilding';
import { TStaticBuildings } from 'types/gis/ExistingBuilding';
import { proxyWithHistory } from 'valtio/utils';

interface IBuildingStoreState {
  projectBuildings: TBuildings;
  staticBuildings: TStaticBuildings;
  existingEditableBuildings: TExistingEditableBuildings;
}

const buildingStore = proxyWithHistory<IBuildingStoreState>(
  {
    projectBuildings: {},
    existingEditableBuildings: {},
    staticBuildings: {
      existingBuildings: [],
      unidentifiedBuildings: [],
    },
  },
  false,
);

export const resetBuildingStoreHistory = () => {
  const lastItem = buildingStore.history.snapshots[buildingStore.history.index];

  buildingStore.history.wip = undefined;
  buildingStore.history.index = 0;
  buildingStore.history.snapshots = [lastItem];
};

export default buildingStore;
