import { GetSubsidiesFromPronovoRequest, UpdatePhotovoltaicsRequest } from 'api/dtos/building/building/input';
import { IPvTariffResponse } from 'api/dtos/building/building/output';
import { ProjectIdRequest } from 'api/dtos/general/request';
import {
  GetProjectEnergyResponse,
  GetSolarIrradiationResponse,
  UpdatePhotovoltaicsResponse,
} from 'api/dtos/project/output';
import { setSolarLimits, setSolarSegments } from 'cityview/store/mutations/data';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { ILngLat } from 'types/location/coordinates';
import { RequestMethod } from 'types/request';

export const projectSolarApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getSolarIrradiation: builder.query<GetSolarIrradiationResponse | null, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/solar/`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.SOLAR_IRRADIATION],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const query = await queryFulfilled;

          if (query.data) {
            setSolarSegments(query.data.segments);
            setSolarLimits(query.data.limits);
            dispatch(projectSolarApiSlice.util.invalidateTags([EBackendTag.ENERGY]));
          }
        } catch {}
      },
    }),
    getProjectEnergy: builder.query<GetProjectEnergyResponse | null, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/energy/`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.ENERGY],
    }),
    updatePhotovoltaics: builder.mutation<UpdatePhotovoltaicsResponse, UpdatePhotovoltaicsRequest>({
      query: ({ projectId, buildings }) => ({
        url: `/api/project/${projectId}/energy/`,
        method: RequestMethod.POST,
        body: buildings,
      }),
      invalidatesTags: [EBackendTag.ENERGY, EBackendTag.BUILDINGS],
    }),
    getSubsidiesFromPronovo: builder.query<number, GetSubsidiesFromPronovoRequest>({
      query: ({ projectId, building }) => ({
        url: `/api/solar/subsidies/pronovo/${projectId}`,
        method: RequestMethod.POST,
        body: building,
      }),
    }),
    getGridPriceFromPvTariff: builder.query<IPvTariffResponse, ILngLat>({
      query: ({ lng, lat }) => ({
        url: `/api/solar/pv-tariff/${lng}/${lat}`,
        method: RequestMethod.GET,
      }),
    }),
  }),
});

export const {
  useGetSolarIrradiationQuery,
  useGetProjectEnergyQuery,
  useUpdatePhotovoltaicsMutation,
  useLazyGetSubsidiesFromPronovoQuery,
  useGetGridPriceFromPvTariffQuery,
} = projectSolarApiSlice;

export const useGetSolarIrradiationQueryState = projectSolarApiSlice.endpoints.getSolarIrradiation.useQueryState;
export const useGetProjectEnergyQueryState = projectSolarApiSlice.endpoints.getProjectEnergy.useQueryState;
