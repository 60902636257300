const PRICING_UNITS = {
  CURRENCY: 'CHF',
};

const SPATIAL_UNITS = {
  MILLIMETER: 'mm',
  CENTIMETER: 'cm',
  METER: 'm',
  KILOMETER: 'km',
  SQUAREMETER: 'm²',
  CUBICMETER: 'm³',
  DEGREES: '°',
  ABOVE_SEA_LEVEL: 'M.ü.M.',
};

const ENERGY_UNITS = {
  RP_PER_KWH: 'Rp/kWh',
  KWH: 'kWh',
  KWH_PER_SQUAREMETER: 'kWh/m²',
  W_PER_SQUAREMETER: 'W/m²',
  KWP: 'kWp',
  KWH_PER_KWP: 'kWh/kWp',
  CURRENCY_PER_KWH: 'CHF/kWh',
  CURRENCY_PER_KWP: 'CHF/kWp',
};

const GENERAL_UNITS = {
  PERCENT: '%',
  HOUR: 'h',
  PIECE: 'Stk',
};

const VOLUME_UNITS = {
  CUBICMETER_PER_HOUR: `${SPATIAL_UNITS.CUBICMETER}/${GENERAL_UNITS.HOUR}`,
  CUBICMETER_PER_SQUAREMETER: `${SPATIAL_UNITS.CUBICMETER}/${SPATIAL_UNITS.SQUAREMETER}`,
};

const COMBINED_UNITS = {
  CURRENCY_PER_SQUAREMETER: `${PRICING_UNITS.CURRENCY}/${SPATIAL_UNITS.SQUAREMETER}`,
  CURRENCY_PER_CUBICMETER: `${PRICING_UNITS.CURRENCY}/${SPATIAL_UNITS.CUBICMETER}`,
  CURRENCY_PER_CUBICMETER_PER_HOUR: `${PRICING_UNITS.CURRENCY}/(${VOLUME_UNITS.CUBICMETER_PER_HOUR})`,
  CURRENCY_PER_PIECE: `${PRICING_UNITS.CURRENCY}/${GENERAL_UNITS.PIECE}`,
};

const UNITS = {
  ...PRICING_UNITS,
  ...SPATIAL_UNITS,
  ...COMBINED_UNITS,
  ...GENERAL_UNITS,
  ...VOLUME_UNITS,
  ...ENERGY_UNITS,
};

export default UNITS;
