import cloneDeep from 'lodash/cloneDeep';
import * as THREE from 'three';
import { XY } from 'types/location/coordinates';
import { TTerrain } from 'types/terrain';
import { derive, proxyWithHistory } from 'valtio/utils';
import { IModifiedTerrain } from '../types';

interface ITerrainStoreState {
  modifiedTerrain: IModifiedTerrain;
  buildingsVisible: string[];
  originalTerrain: {
    id?: string;
    bottomGeometry?: THREE.BufferGeometry;
    overlayGeometry?: THREE.BufferGeometry;
    terrainData?: TTerrain;
    terrainXYIndices: Map<string, XY>;
    midpointElevation?: number;
  };
}

const terrainStore = proxyWithHistory<ITerrainStoreState>(
  {
    modifiedTerrain: {
      edgePoints: [],
      innerPoints: [],
    },
    buildingsVisible: [],
    originalTerrain: {
      id: undefined,
      bottomGeometry: undefined,
      overlayGeometry: undefined,
      terrainData: undefined,
      terrainXYIndices: new Map(),
    },
  },
  false,
);

export const resetTerrainStoreHistory = () => {
  const lastItem = terrainStore.history.snapshots[terrainStore.history.index];

  terrainStore.history.wip = undefined;
  terrainStore.history.index = 0;
  terrainStore.history.snapshots = [lastItem];
};

export default terrainStore;

export const derivedTerrainStore = derive({
  mergedModifiedTerrain: (get) => {
    const storeValue = get(terrainStore.value);
    const terrain = storeValue.originalTerrain.terrainData;
    const terrainXYIndices = storeValue.originalTerrain.terrainXYIndices;
    const { innerPoints } = storeValue.modifiedTerrain;

    if (!terrain || innerPoints.length === 0) {
      return;
    }

    const mergedTerrain = cloneDeep(terrain);

    innerPoints.forEach((point) => {
      const indices = terrainXYIndices.get(`${point[0]},${point[1]}`);
      if (!indices) {
        return;
      }

      mergedTerrain[indices[0]][indices[1]][2] = point[2];
    });

    return mergedTerrain;
  },
});
