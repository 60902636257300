import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EReportSection, ProjectReportSettingsInput } from 'api/dtos/project/input';
import { projectSolarApiSlice } from 'state/services/backend/endpoints/project/projectSolar';
import { IActiveSwissTopoBuilding } from './cityView';

interface ICopiedFloor {
  buildingId: string;
  floorId: string;
}

export interface ProjectState {
  copiedFloor: ICopiedFloor | null;
  cityView: {
    activeSwissTopoBuilding: IActiveSwissTopoBuilding | null;
    selectedBufferIndex: number | null;
  };
  polling: {
    pollGetSolarIrradiationQuery: boolean;
  };
  views: {
    lastDesignView: string | null;
  };
  downloadSettings: {
    projectSummary: ProjectReportSettingsInput;
  };
}

const initialState: ProjectState = {
  copiedFloor: null,
  cityView: {
    activeSwissTopoBuilding: null,
    selectedBufferIndex: null,
  },
  views: {
    lastDesignView: null,
  },
  polling: {
    pollGetSolarIrradiationQuery: false,
  },
  downloadSettings: {
    projectSummary: {
      hasImages: true,
      language: null,
      sections: {
        [EReportSection.SUMMARY]: {
          show: true,
        },
        [EReportSection.PLOTS]: {
          show: true,
        },
        [EReportSection.RESTRICTIONS]: {
          show: true,
        },
        [EReportSection.MUNICIPALITY_LAW]: {
          show: true,
        },
        [EReportSection.CANTON_LAW]: {
          show: true,
        },
        [EReportSection.SITUATION]: {
          show: true,
        },
        [EReportSection.UTILIZATION]: {
          show: true,
        },
        [EReportSection.AREAS]: {
          show: true,
        },
        [EReportSection.DIMENSIONS]: {
          show: true,
        },
        [EReportSection.DIMENSION_IMAGES]: {
          show: true,
        },
        [EReportSection.BUILDING_UNITS]: {
          show: true,
        },
        [EReportSection.CHECKLIST]: {
          show: true,
        },
        [EReportSection.COSTS]: {
          show: true,
        },
        [EReportSection.INCOMES]: {
          show: true,
        },
        [EReportSection.BUILDINGS]: {
          show: true,
        },
        [EReportSection.DATA_OVERVIEW]: {
          show: true,
        },
      },
    },
  },
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clear: () => initialState,
    setActiveSwissTopoBuilding(state, action: PayloadAction<IActiveSwissTopoBuilding | null>) {
      state.cityView.activeSwissTopoBuilding = action.payload;
    },
    setCopiedFloor(state: ProjectState, action: PayloadAction<ICopiedFloor>) {
      state.copiedFloor = action.payload;
    },
    setLastDesignView(state, action: PayloadAction<string | null>) {
      state.views.lastDesignView = action.payload;
    },
    setProjectSummaryDownloadSectionsSettings(
      state,
      action: PayloadAction<Partial<ProjectReportSettingsInput['sections']>>,
    ) {
      state.downloadSettings.projectSummary.sections = {
        ...state.downloadSettings.projectSummary.sections,
        ...action.payload,
      };
    },
    setProjectSummaryDownloadSettings(state, action: PayloadAction<Partial<ProjectReportSettingsInput>>) {
      state.downloadSettings.projectSummary = {
        ...state.downloadSettings.projectSummary,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(projectSolarApiSlice.endpoints.getSolarIrradiation.matchFulfilled, (state, action) => {
      state.polling.pollGetSolarIrradiationQuery = !action.payload;
    });
    builder.addMatcher(projectSolarApiSlice.endpoints.getSolarIrradiation.matchRejected, (state, action) => {
      if (action.payload && ('error' in action.payload || action.payload.status === 406)) {
        state.polling.pollGetSolarIrradiationQuery = false;
      }
    });
  },
});

export const {
  clear,
  setActiveSwissTopoBuilding,
  setCopiedFloor,
  setLastDesignView,
  setProjectSummaryDownloadSectionsSettings,
  setProjectSummaryDownloadSettings,
} = projectSlice.actions;
